'use client'

import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ConfigurableProductOptionFragment,
  ConfigurableProductVariantFragment,
  ProductStockStatus,
} from '@/api'
import { cn } from '@/common/utils/lib'
import { obsoloteGetPrice } from '@/utils'
import { useCartContext } from '@/providers'
import { selectors } from '@/common/constants/selectors-constants'
import { delayBeforeClosingProductModal } from '@/common/constants/products'
import { useAddToCartBtnText } from '@/common/hooks/use-add-to-cart-btn-text'
import { ProductDetailVariantsAddToCartButton } from './variants-add-to-cart-button'
import { useProductDataContext } from '@/providers/product-data/obsolote-product-data-context'
import {
  obsoleteGetHandleAttributeChange,
  obsoleteGetConfigurableVariantAttributesRecord,
} from './utils'
import { VariantsCounter } from './variants-counter'
import { ConfigurableVariant } from './obsolete-configurable-variant'

interface ProductConfigurableVariants {
  name?: string | null
  sku?: string | null
  manufacturer_info?: {
    name?: string | null
  } | null
  breadcrumb_en?: string | null
  configurable_variants?:
    | (ConfigurableProductVariantFragment | undefined | null)[]
    | null
  configurable_options?:
    | (ConfigurableProductOptionFragment | undefined | null)[]
    | null
}

export type ConfigurableVariantsProps = {
  configurableProductData: ProductConfigurableVariants
  isPopupVariant?: boolean
  onClose?: () => void
}

export function ConfigurableVariants({
  onClose,
  configurableProductData,
  isPopupVariant = false,
}: ConfigurableVariantsProps): JSX.Element {
  const {
    configurable_options: configurableOptions,
    configurable_variants: configurableVariants,
  } = configurableProductData

  const {
    configurableProductVariant,
    setConfigurableProductVariant,
    productVariantCount,
    setProductVariantCount,
  } = useProductDataContext()
  const { isAddingToCart, isCartRefetching, addToCart, isCartFetchError } =
    useCartContext()

  const [changeText, setChangeText] = useState(false)

  const options = useMemo(
    () =>
      (configurableOptions
        ?.filter((option) => !!option)
        ?.sort(
          (optionA, optionB) =>
            (optionA?.position ?? 0) - (optionB?.position ?? 0),
        ) ?? []) as ConfigurableProductOptionFragment[],
    [configurableOptions],
  )

  const variants = useMemo(
    () =>
      (configurableVariants?.filter(
        (variant) =>
          variant?.product?.enabled &&
          variant?.product?.stock_status === ProductStockStatus.InStock,
      ) ?? []) as ConfigurableProductVariantFragment[],
    [configurableVariants],
  )

  const isMultipleVariants = useMemo(
    () =>
      variants?.every((variant) =>
        variant?.attributes?.every(
          (attribute) => attribute?.label !== 'single_variant',
        ),
      ),
    [variants],
  )

  /**
   * All possible values (attributes) corresponding with selected configurableProductVariant
   * in a structure Record<productOption.attribute_code, ProductConfigurableVariantAttribute>
   */
  const variantAttributesRecord = useMemo(
    () =>
      obsoleteGetConfigurableVariantAttributesRecord({
        options,
        variants,
        selectedVariant: configurableProductVariant,
      }),
    [options, variants, configurableProductVariant],
  )

  const handleAttributeChangeForVariant = useMemo(
    () =>
      obsoleteGetHandleAttributeChange(
        configurableProductVariant,
        variants,
        setConfigurableProductVariant,
      ),
    [configurableProductVariant, variants, setConfigurableProductVariant],
  )

  const handleAddToCart = useCallback(async () => {
    if (addToCart) {
      const { finalPrice } = obsoloteGetPrice(
        configurableProductVariant?.product,
      )

      setChangeText(true)

      await addToCart({
        productName: configurableProductData?.name ?? '',
        parentSku: configurableProductData?.sku ?? '',
        sku: configurableProductVariant?.product?.sku ?? '',
        quantity: productVariantCount,
        brand: configurableProductData?.manufacturer_info?.name ?? '',
        price: finalPrice?.value_excl_tax
          ? Math.round(finalPrice?.value_excl_tax * 100) / 100
          : NaN,
        category: configurableProductData?.breadcrumb_en ?? '',
      })
    }

    if (onClose) {
      setTimeout(() => onClose(), delayBeforeClosingProductModal)
    }
  }, [
    onClose,
    addToCart,
    productVariantCount,
    configurableProductData,
    configurableProductVariant,
  ])

  const disabled = isCartRefetching || !addToCart

  useEffect(() => {
    if (!disabled) {
      setChangeText(false)
    }
  }, [disabled])

  const buttonText = useAddToCartBtnText({
    changeText,
    isAddingToCart,
    isCartRefetching,
    isCartFetchError,
  })

  return (
    <>
      <div className="mb-4 flex flex-wrap">
        <div
          className={cn(
            'w-full',
            'flex flex-col flex-wrap',
            isPopupVariant ? '' : 'md:flex-row md:items-center',
          )}
          data-test={selectors.HP.productVariantSection}
        >
          {isMultipleVariants &&
            options.map((option) => (
              <ConfigurableVariant
                key={option.attribute_code}
                option={option}
                disabled={disabled}
                handleAttributeChangeForVariant={
                  handleAttributeChangeForVariant
                }
                variantAttributesRecord={variantAttributesRecord}
                configurableProductVariant={configurableProductVariant}
                className={cn(
                  'w-full',
                  isPopupVariant ? '' : 'max-w-none md:w-fit md:max-w-[180px]',
                )}
              />
            ))}

          <VariantsCounter
            isDisabled={disabled}
            count={productVariantCount}
            onAddToCart={handleAddToCart}
            onCountChange={setProductVariantCount}
            className={cn('w-[120px]', isPopupVariant ? '' : 'md:w-[105px]')}
          />
        </div>
      </div>

      <ProductDetailVariantsAddToCartButton
        buttonText={buttonText}
        addToCart={handleAddToCart}
        className="md:w-full lg:w-full"
      />
    </>
  )
}
